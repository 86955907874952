/**
 * Campaigns Mapper
 * @returns campaigns mapper
 */
export const campaignsMapper = () => {
  return {
    'campaign-viewer': ['campaign:read', 'campaign/segment:read', 'campaign/contact-lists:read', 'campaign/image:read', 'audiences:read', 'app/campaign:read', 'app/audience:read', 'roi-partial-attribution:read', 'crm:read'],
    'campaign-audience-read-write-access': ['campaign:read', 'campaign/segment:read', 'campaign/contact-lists:read', 'campaign/image:read', 'campaign:write', 'campaign/image:write', 'audiences:read', 'audiences:write'],
    'campaign-read-write-access': ['campaign:read', 'campaign/segment:read', 'campaign/contact-lists:read', 'campaign/image:read', 'campaign:write', 'campaign/image:write'],
    'create-mail-list-read-write-access': ['jobs:read', 'jobs:write', 'jobs:write:export-contact-list', 'contact-lists:read', 'contact-lists/download:read', 'contact-lists:write', 'v0_2/contact-lists:read', 'v0_2/contact-lists/download:read', 'v0_2/contact-lists/download-url:read', 'v0_2/contact-lists:write'],
    'send-ma-read-write-access': ['jobs:read', 'jobs:write', 'jobs:write:sync-contacts'],
    'export-audience-read': ['export/audience:read'],
    'contact-dns-write': ['app/contact-dns:write'],
    'report-read-write-access': ['report:write', 'contact-lists:write'],
    'report-read-access': ['contact-lists:read', 'report:read'],
    'report-write-access': ['report:write'],
    'contact-list-write-access': ['v0_2/contact-lists:write'],
    'contact-list-download-access': ['contact-lists/download:read', 'v0_2/contact-lists/download:read'],
    'campaign-tactic-read-access': ['campaign/tactic-link:read'],
    'campaign-tactic-bulk-import-access': ['campaign:write', 'campaign/tactic-link:read'],
    'campaign-performance-report': ['campaign/performance-report:read', 'reports-session/link:read'],
    'campaign-hybrid-report': ['campaign/hybrid-reports:read', 'reports-session/link:read'],
    'campaign-manager': ['campaign/manage:write'],
    'campaign-read': ['campaign:read'],
  };
};
